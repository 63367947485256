<script>
import BadgeLevel from '@/components/badge-level'
import BadgeState from '@/components/badge-state'
import Modify from '@/components/admin/modify'
import AddChildren from './add-children'

export default {
  components: {
    BadgeLevel,
    BadgeState,
    Modify,
    AddChildren
  },
  props: {
    level: String,
    state: String
  },
  data() {
    return {
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        beginDate: '',
        endDate: '',
        state: ''
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1 // 현재 페이지
    }
  },
  created() {

  },
  mounted() {
    /*
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 365)
    let endDate = new Date()

    this.search.beginDate = beginDate
    this.search.endDate = endDate
    */
    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.searchList().then()
  },
  methods: {
    openModify: function (partner) {
      this.$refs['modal-modify'].open(partner)
    },
    setSort: function (field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      // this.searchList().then()
    },
    searchByPage: function (page) {
      this.page = page
      this.searchList().then()
    },
    searchList: async function () {
      const loader = this.$loading.show({container: this.$refs.contentWrapper})
      try {
        localStorage.setItem('limit', this.limit)

        const params = {
          search: {state: this.state, level: this.level},
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText
        params.search.state = this.state

        const result = await this.$API.admin.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = result.documents
        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount ? result.totalPageCount : 1
        this.page = result.page
      } catch (e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    addChildren: function(parent) {
      this.$refs['modal-add-children'].open(parent)
    },
  }
}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList">
      <div class="row">
        <div class="col-md-6"></div>

        <div class="form-group col-md-1 mb-2">
          <select class="form-select" v-model="search.searchType">
            <option value="username">{{ $t('아이디') }}</option>
            <option value="siteUsername">{{ $t('사이트 아이디') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>

        <div class="col-md-1 mb-1">
          <select class="form-select" v-model="limit" @change="searchList">
            <option :value="10">10개</option>
            <option :value="25">25개</option>
            <option :value="50">50개</option>
            <option :value="100">100개</option>
          </select>
        </div>

        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button class="btn btn-warning w-100" type="button" @click="addChildren($store.getters['auth/user'])" v-if="$store.getters['auth/user'].type === 'admin'">{{$t('생성')}}</button>
        </div>
      </div>

    </form>

    <hr class="mt-0 mb-3" />

    <div class="table-responsive mb-0">
      <table class="table align-middle table-nowrap sticky-table">
        <thead class="table-dark">
        <tr>
          <th class="align-middle">
            <a href="javascript:void(0)" @click="setSort('createdAt')">#</a>&nbsp;
            <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
          <th class="align-middle">{{ $t('회원정보') }}</th>
          <th class="align-middle">{{ $t('상태') }}</th>
          <th class="align-middle">
            <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('시간') }}</a>&nbsp;
            <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="record in list" :key="record.id">
          <td>
            <div class="dropdown">
              <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                <span class="mdi mdi-menu"></span>
              </button>
              <ul class="dropdown-menu">
                <li><h5 class="dropdown-header">{{ record.id }}</h5></li>
                <li class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="javascript:void(0)" @click="openModify(record)">{{ $t('정보 수정') }}</a></li>
              </ul>
            </div>
          </td>
          <td class="text-start">
            <div class="row">
              <div class="col-md-12">
                <badge-level :show-name="false" :partner="record"></badge-level>
                <span>{{ record.username }}<small> ({{ record.nickname }})</small></span>
              </div>
            </div>
            <div class="row" v-if="record.memo">
              <div class="col-md-12 border-top mt-2 pt-1 small text-secondary">
                {{ record.memo }}
              </div>
            </div>
          </td>
          <td>
            <badge-state :user="record"></badge-state>
          </td>
          <td class="small">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="First">
          <i class="mdi mdi-chevron-double-left"></i>
        </a>
      </li>
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ 'active': pageIndex === page }" v-for="pageIndex in totalPageCount" :key="'page-' + pageIndex">
        <a class="page-link" href="javascript: void(0)" @click="searchByPage(pageIndex)">{{ pageIndex }}</a>
      </li>
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="Previous">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="First">
          <i class="mdi mdi-chevron-double-right"></i>
        </a>
      </li>
    </ul>

    <!-- 하부 생성 //-->
    <add-children ref="modal-add-children" @searchList="searchList" />

    <!-- 정보수정 //-->
    <modify ref="modal-modify" @searchList="searchList"></modify>
  </div>
</template>
