<script>

export default {
  components: {

  },
  data() {
    return {
      admin: null,
      openDialog: false,
      params: {
        username: '',
        password: '',
        passwordConfirm: '',
        nickname: '',
        language: 'ko',
        currency: 'KRW',
        timezone: 9,
        memo: ''
      }
    };
  },
  mounted() {

  },
  methods: {
    open: function(admin) {
      this.openDialog = true

      // 데이터 초기화
      this.admin = admin

      this.params.username = admin.username
      this.params.password = ''
      this.params.passwordConfirm = ''
      this.params.nickname = admin.nickname
      this.params.language = admin.language
      this.params.currency = admin.currency
      this.params.timezone = admin.timezone
      this.params.level= admin.level
      this.params.email = admin.email
      this.params.memo = admin.memo
    },
    update: async function() {
      const loader = this.$loading.show({ container: this.$refs.formWrapper })
      try {
        const result = await this.$API.admin.update(this.admin.id, this.params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.openDialog = false

        this.$emit('searchList')

      } catch (e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
};
</script>

<template>
  <div ref="formWrapper">
    <b-modal
        :title="`${$t('정보 수정')}`"
        v-model="openDialog"
        size="lg"
        scrollable="true"
        centered="true"
        button-size="sm"
        hide-footer="true"
    >
      <form @submit.prevent="update">
        <div class="row">
          <div class="col-md-6">

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('로그인 아이디')" v-model="params.username" :disabled="$store.getters['auth/user'].type !== 'admin'" />
              <label class="form-label">{{ $t('로그인 아이디') }}</label>
            </div>

            <div class="form-floating mb-3" :aria-disabled="$store.getters['auth/user'].type !== 'admin'">
              <input type="password" class="form-control" :placeholder="$t('비밀번호')" v-model="params.password" :disabled="$store.getters['auth/user'].type !== 'admin'" />
              <label class="form-label">{{ $t('비밀번호') }}</label>
            </div>

            <div class="form-floating mb-3" :aria-disabled="$store.getters['auth/user'].type !== 'admin'">
              <input type="password" class="form-control" :placeholder="$t('비밀번호 확인')" v-model="params.passwordConfirm" :disabled="$store.getters['auth/user'].type !== 'admin'" />
              <label class="form-label">{{ $t('비밀번호 확인') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('별칭')" v-model="params.nickname" :disabled="$store.getters['auth/user'].type !== 'admin'" />
              <label class="form-label">{{ $t('별칭') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="`${$t('메모')}`" maxlength="250" v-model="params.memo" />
              <label class="form-label">{{ `${$t('메모')}` }}</label>
            </div>

          </div>
          <div class="col-md-6">

            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('언어')" v-model="params.language">
                <option value="ko">한국어</option>
                <!--
                <option value="en">English</option>
                //-->
              </select>
              <label class="form-label">{{ $t('언어') }}</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('통화')" v-model="params.currency">
                <option value="KRW">KRW</option>
                <!--
                <option value="USD">USD</option>
                <option value="CNY">CNY</option>
                //-->
              </select>
              <label class="form-label">{{ $t('통화') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="number" class="form-control" :placeholder="`${$t('타임존')}`" v-model="params.timezone" />
              <label class="form-label">{{ `${$t('타임존')}` }}</label>
            </div>
          </div>
        </div>
        <div class="text-end">
          <hr />
          <button type='submit' class='btn btn-primary me-1'>{{ $t('수정') }}</button>
          <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
