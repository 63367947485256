<script>

export default {
  components: {

  },
  data() {
    return {
      openDialog: false,
      params: {
        username: '',
        password: '',
        passwordConfirm: '',
        nickname: '',
        language: 'ko',
        currency: 'KRW',
        timezone: 9,
        type: 'admin',
        level: '',
        memo: ''
      }
    };
  },
  mounted() {

  },
  methods: {
    open: function() {
      this.openDialog = true

      this.params.username = ''
      this.params.password = ''
      this.params.passwordConfirm = ''
      this.params.nickname = ''
      this.params.language = 'ko'
      this.params.currency = 'KRW'
      this.params.timezone = 9
      this.params.type = 'admin'
      this.params.level= 'helpdesk'
      this.params.email = ''
      this.params.memo = ''
    },
    register: async function() {
      const loader = this.$loading.show({ container: this.$refs.formWrapper })
      try {
        const result = await this.$API.admin.create(this.params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.openDialog = false

        this.$emit('searchList')

      } catch (e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
};
</script>

<template>
  <div ref="formWrapper">
    <b-modal
        :title="`${$t('관리자 생성')}`"
        v-model="openDialog"
        size="lg"
        scrollable="true"
        centered="true"
        button-size="sm"
        hide-footer="true"
    >
      <form @submit.prevent="register">
        <div class="row">
          <div class="col-md-6">

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('로그인 아이디')" v-model="params.username" />
              <label class="form-label">{{ $t('로그인 아이디') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="password" class="form-control" :placeholder="$t('비밀번호')" v-model="params.password" />
              <label class="form-label">{{ $t('비밀번호') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="password" class="form-control" :placeholder="$t('비밀번호 확인')" v-model="params.passwordConfirm" />
              <label class="form-label">{{ $t('비밀번호 확인') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('별칭')" v-model="params.nickname" />
              <label class="form-label">{{ $t('별칭') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="`${$t('메모')}`" maxlength="250" v-model="params.memo" />
              <label class="form-label">{{ `${$t('메모')}` }}</label>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('레벨')" v-model="params.level">
                <option value="company">회사</option>
                <option value="admin">관리자</option>
                <option value="helpdesk">고객센터</option>
              </select>
              <label class="form-label">{{ $t('언어') }}</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('언어')" v-model="params.language">
                <option value="ko">한국어</option>
                <!--
                <option value="en">English</option>
                //-->
              </select>
              <label class="form-label">{{ $t('언어') }}</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('통화')" v-model="params.currency">
                <option value="KRW">KRW</option>
                <!--
                <option value="USD">USD</option>
                <option value="CNY">CNY</option>
                //-->
              </select>
              <label class="form-label">{{ $t('통화') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="number" class="form-control" :placeholder="`${$t('타임존')}`" v-model="params.timezone" />
              <label class="form-label">{{ `${$t('타임존')}` }}</label>
            </div>
          </div>
        </div>
        <div class="text-end">
          <hr />
          <button type='submit' class='btn btn-primary me-1'>{{ $t('생성') }}</button>
          <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
