<template>
  <span class="badge bg-success p-1" v-if="user.state === 'normal'">{{ $t('정상') }}</span>
  <span class="badge bg-warning p-1" v-if="user.state === 'suspended'">{{ $t('차단') }}</span>
  <span class="badge bg-danger p-1" v-if="user.state === 'unregistered'">{{ $t('탈퇴') }}</span>
</template>

<script>
export default {
  props: {
    user: { type: Object }
  },
  methods: {

  },
  computed: {

  }
}
</script>
